import { useState, useEffect } from 'react';
import { driverTeams } from './DriverTeams'
import './ChampionshipPoints.css'
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

const ChampionshipPoints = (props) => {
  const [driverChamptionshipPoints, setDriverChamptionshipPoints] = useState([])
  const [previousWeeksDriverChampPoints, setPreviousWeeksDriverChampPoints] = useState([])
  const [constructorChamptionshipPoints, setConstructorChamptionshipPoints] = useState([])
  const [previousWeeksConstructorChampPoints, setPreviousWeeksConstructorChampPoints] = useState([])
  const [leagueDrivers] = useState([
    'Golden_Bullet_03',
    'IRaZoR_JS',
    'Liskybusiness',
    'VZDe09',
    'account986',
    'BunkyB',
    'mrseagull1982',
    'xLIAM_Ux',
    'guuunnnaaa',
    'spector9600',
    'king_lebowski',
    'mReliga',
    'VinnieDuL',
    'andrethorn',
    'TREV1204',
    'Skitzy1980',
    'redmannnnns',
    'Robbie Shankster',
    'Phillam87',
    'KUMO',
    'Daniel Anderson'
  ])
  const [constructorTeams] = useState([
    {team:'Red Bull' ,driver01: 'king_lebowski', driver02: 'spector9600',},
    {team:'Mercedes-AMG Petronas' ,driver01: 'VZDe09', driver02: 'xLIAM_Ux',},
    {team:'Alfa Romeo' ,driver01: 'Liskybusiness', driver02: 'guuunnnaaa',},
    {team:'Alpine' ,driver01: 'Golden_Bullet_03', driver02: 'VinnieDuL',},
    {team:'Aston Martin' ,driver01: 'IRaZoR_JS', driver02: 'BunkyB',},
    {team:'McLaren' ,driver01: 'account986', driver02: 'Skitzy1980',},
    {team:'Alpha Tauri' ,driver01: 'mrseagull1982', driver02: 'andrethorn',},
    {team:'Williams' ,driver01: 'redmannnnns', driver02: 'Robbie Shankster',},
    {team:'Ferrari' ,driver01: 'mReliga', driver02: 'TREV1204',},
    {team:'Haas' ,driver01: 'KUMO', driver02: 'Phillam87',},
  ])

  useEffect(() => {
    if (driverChamptionshipPoints.length === 0) {
      const { rounds } = props

      //console.log('champ round = ', rounds)

      var driverPointsArray = []
      var constructorPoints = []
      var previousWeeksResults = []
      var previousWeeksConstructorPoints = []

      //console.log('$$$ driverPointsArray = ', driverPointsArray)
      //console.log('previousWeeksResults = ', previousWeeksResults)

      for (var i = 0; i < rounds.length; i++) {

        //console.log('*** Round = ', i)

        const prevousRound = rounds.length - 2


        for (var n = 0; n < leagueDrivers.length; n++) {
          
          const found = rounds[i].race.find(element => element.name === leagueDrivers[n]);

          // console.log('found = ', found)
          // console.log('found.position = ', found.position)

          const driverPointsIndex = driverPointsArray.findIndex(element => element.name === leagueDrivers[n])

          //console.log('driverPointsIndex = ', driverPointsIndex)
           
          if (driverPointsIndex < 0 && found) {
            //console.log('!!! driver raced !!!', leagueDrivers[n])
            driverPointsArray.push({name:leagueDrivers[n], points:found.points, positionsSum:found.position})
            if (i === prevousRound) {
              previousWeeksResults.push({name:leagueDrivers[n], points:found.points})
            }

          } else if (driverPointsIndex < 0 && !found) {
            //console.log('*** driver has not raced yet ***')
            //console.log(leagueDrivers[n], 'driverPointsIndex < 0 && !found NOT FOUND')
            driverPointsArray.push({name:leagueDrivers[n], points:0, positionsSum:21})
            if (i === prevousRound) {
              //console.log('prevousRound')
              previousWeeksResults.push({name:leagueDrivers[n], points:0, positionsSum:21})
            }

          } else if (found) {
            //console.log(leagueDrivers[n], 'ELSE IF')
            const newPoints = found.points + driverPointsArray[driverPointsIndex].points
            //console.log('newPoints = ', newPoints)
            const newPositionSum = found.position + driverPointsArray[driverPointsIndex].positionsSum
            //console.log('newPositionSum = ', newPositionSum)
            driverPointsArray[driverPointsIndex] = {name:leagueDrivers[n], points:newPoints, positionsSum: newPositionSum}
            if (i === prevousRound) {
              //console.log('prevousRound')
              previousWeeksResults.push({name:leagueDrivers[n], points:newPoints, positionsSum:newPositionSum})
            }
          } else if (driverPointsIndex >= 0) {
            //console.log(leagueDrivers[n], 'driverPointsIndex >= 0')
            //console.log('driverPointsArray[driverPointsIndex] = ', driverPointsArray[driverPointsIndex])
            const newPositionSum = driverPointsArray[driverPointsIndex].positionsSum + 21
            //console.log('newPositionSum = ', newPositionSum)
            //driverPointsArray.push({name:leagueDrivers[n], points:0})
            //const newPoints = found.points + driverPointsArray[driverPointsIndex].points
            //console.log('driverPointsArray = ', driverPointsArray)
            if (i === prevousRound) {
              //console.log('prevousRound')
              previousWeeksResults.push({name:leagueDrivers[n], points:driverPointsArray[driverPointsIndex].points, positionsSum:newPositionSum})
            }
          } 

        }

        //console.log('driverPointsArray = ', driverPointsArray)
      }

      //console.log('driverPointsArray = ', driverPointsArray)

      driverPointsArray.sort(function(a, b){return b.points - a.points});
      //console.log('driverPointsArray = ', driverPointsArray)
      // driverPointsArray.sort(function(a, b){return a.positionsSum - b.positionsSum});
      // //driverPointsArray.sort()
      // console.log('driverPointsArray = ', driverPointsArray)
      const finalDriverPointsArray = positionCalulator(driverPointsArray)
      
      //console.log('finalDriverPointsArray = ', finalDriverPointsArray)

      previousWeeksResults.sort(function(a, b){return b.points - a.points});

      const finalPreviousWeeksResults = positionCalulator(previousWeeksResults)

      setDriverChamptionshipPoints(finalDriverPointsArray)
      setPreviousWeeksDriverChampPoints(finalPreviousWeeksResults)
      // console.log('previousWeeksResults = ', previousWeeksResults)
      // console.log('!!! driverPointsArray = ', driverPointsArray)

      for (var a = 0; a < constructorTeams.length; a++) {
        const { team, driver01, driver02} = constructorTeams[a]
        const points = calculateConstructorPoints(driverPointsArray, driver01, driver02)
        const drivers = `${driver01}, ${driver02}`
        constructorPoints.push({team, points:points, drivers,})
        const preivousWeeksPoints = calculateConstructorPoints(previousWeeksResults, driver01, driver02)
        previousWeeksConstructorPoints.push({team, points:preivousWeeksPoints, drivers})
      }

      constructorPoints.sort(function(a, b){return b.points - a.points});
      previousWeeksConstructorPoints.sort(function(a, b){return b.points - a.points});
      setConstructorChamptionshipPoints(constructorPoints)
      setPreviousWeeksConstructorChampPoints(previousWeeksConstructorPoints)
      //console.log('constructorPoints = ', constructorPoints)
      //console.log('previousWeeksConstructorPoints = ', previousWeeksConstructorPoints)
    }
  }, [driverChamptionshipPoints.length])

  const positionCalulator = (arrayToSort) => {
    //console.log('position calculator called')
    //console.log('arrayToSort = ', arrayToSort)
    var driverWithZeroPoints = []

    for (var i = 0; i < arrayToSort.length; i++) {
      if (arrayToSort[i].points === 0) {
        driverWithZeroPoints.push(arrayToSort[i])
        //console.log('Driver with 0 points = ', arrayToSort[i])
      }
    }
    
    //console.log('driverWithZeroPoints = ', driverWithZeroPoints)

    driverWithZeroPoints.sort(function(a, b){return a.positionsSum - b.positionsSum});

    //console.log('driverWithZeroPoints = ', driverWithZeroPoints)

    var smallerArray = arrayToSort.toSpliced(20-driverWithZeroPoints.length)

    //console.log('smallerArray = ', smallerArray)

    var newPointsArray = smallerArray.concat(driverWithZeroPoints)

    return newPointsArray
  }

  const calculateConstructorPoints = (driverPointsArray, driver1Name, driver2Name) => {
    var driver1Points = driverPointsArray.find(element => element.name == driver1Name);
    var driver2Points = driverPointsArray.find(element => element.name == driver2Name);


    if (!driver1Points) {
      driver1Points = {
        name: driver1Name,
        points: 0,
      }
    }

    if (!driver2Points) {
      driver2Points = {
        name: driver2Name,
        points: 0,
      }
    }

    const constructorPoints = driver1Points.points + driver2Points.points
    return constructorPoints
  }

  const deltaPosition = (previousPos, currntPos) => {
    const delta = previousPos - currntPos
    const deltaAbs = Math.abs(delta)

    //console.log('deltaPosition props = ', props.rounds.length)

    if (delta === 0 || props.rounds.length === 1) {
      return (
        <div className='postion-container'>
          {'-'}
        </div>
      )
    }

    if (delta < 0) {
      return (
        <div className='postion-container'>
          <AiFillCaretDown style={{ color: 'red'}}/>{` ${deltaAbs}`}
        </div>
      )
    }

    return (
      <div className='postion-container'>
        <AiFillCaretUp style={{ color: 'green'}}/>{` ${deltaAbs}`}
      </div>
    )
  }

  const showDriverChampionShipStandings = () => {

    if (driverChamptionshipPoints.length > 0) {
      return (
        <div>
          <div className='results-row row-heading-text'>
            <div className='postion-container'>
              POS
            </div>
            <div className='postion-container'>

            </div>
            <div className='name-container'>
              DRIVER
            </div>
            <div className='team-name-container'>
              TEAM
            </div>
            <div className='postion-container'>
              POINTS
            </div>
          </div>

          {
            driverChamptionshipPoints.map((driverPoints, index) =>{
              const { name, points } = driverPoints
              const previousIndex =  previousWeeksDriverChampPoints.findIndex(element => element.name === name);
              //console.log(name,' ', points)
              //console.log('previousIndex = ', previousIndex)
              return(
                <div key={index} className='results-row grey-background white-text'>
                  <div className='postion-container'>
                    {`${index + 1}`}
                  </div>
                  {deltaPosition(previousIndex,index)}
                  <div className='name-container'>
                    {name}
                  </div>
                  <div className='team-name-container'>
                    {driverTeams(name)}
                  </div>
                  <div className='postion-container'>
                    {points}
                  </div>
                </div>
              )

            })
          }
        </div>
      )
    }
  }

  const showConstructorStandings = () => {
    if (constructorChamptionshipPoints.length > 0) {
      return (
        <div>
          <div className='results-row row-heading-text'>
            <div className='postion-container'>
              POS
            </div>
            <div className='postion-container'>

            </div>
            <div className='name-container'>
              TEAM
            </div>
            <div className='team-name-container'>
              DRIVERS
            </div>
            <div className='postion-container'>
              POINTS
            </div>
          </div>

          {
            constructorChamptionshipPoints.map((teamResults, index) =>{
              const { team, points, drivers } = teamResults
              const previousIndex =  previousWeeksConstructorChampPoints.findIndex(element => element.team === team);
              return (
                <div key={index} className='results-row grey-background white-text'>
                  <div className='postion-container'>
                    {`${index + 1}`}
                  </div>
                  {deltaPosition(previousIndex,index)}
                  <div className='name-container'>
                    {team}
                  </div>
                  <div className='team-name-container'>
                    {drivers}
                  </div>
                  <div className='postion-container'>
                    {points}
                  </div>
                </div>
              )
            })

          }

        </div>
      )
    }
  }

  return (
    <div className='championship-points-container'>
      <h2>{`Driver Championship Standings`}</h2>
      {showDriverChampionShipStandings()}

      <h2>{`Constructor Championship Standings`}</h2>
      {showConstructorStandings()}
    </div>
  )
}

export default ChampionshipPoints;
