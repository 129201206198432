import { useState, useEffect } from 'react';
import '../App.css';
import './DriverValues.css'
import { firebaseDB } from '../firebase'
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import DriverValuesListItem from './DriverRoundValueList/DriverValuesListItem'

const DriverValues = (props) => {
  const [finalResults, setFinalResults] = useState([]);
  const [roundValues, setRoundValues] = useState([]);
  const [driverValueInfo, setDriverValueInfo] = useState([]);

  useEffect(() =>{

    //console.log('props = ', props)
    // console.log('driverValueInfo = ', driverValueInfo)
    //console.log('finalResults = ', finalResults)
    //console.log('roundValues = ', roundValues)


    if (finalResults.length == 0 && roundValues.length == 0) {

      //console.log('calulate results')

      var tempAllDriverValues = []

      for (let n = 0; n < props.finalResults.length; n++) {
        const { alias, value, name, pos, team } = props.finalResults[n]

        var newValue = value
        var valueFromEachRound = []

        for (let i = 0; i < props.roundValues.length; i++) {
          const tempRoundValue = props.roundValues[i].find(x => x.alias === alias);

          //console.log('alias = ', alias)
          //console.log('tempRoundValue = ', tempRoundValue)

          if (tempRoundValue === undefined) {
            //console.log('tempRoundValue === null')

            valueFromEachRound.push({
            incrementalValue:0, 
            racePos:0
            })

          } else {
  
            valueFromEachRound.push({
              incrementalValue:tempRoundValue.incrementalValue, 
              racePos:tempRoundValue.racePos
            })
            newValue = newValue + tempRoundValue.incrementalValue
          }
        }

        tempAllDriverValues.push({
          alias,
          valueFromEachRound:valueFromEachRound,
          newValue,
          previousYearsValue: value,
          name,
          pos,
          team,
        })

      }

      tempAllDriverValues.sort(( a, b ) => { return b.newValue - a.newValue });

      //console.log('tempAllDriverValues = ', tempAllDriverValues)

      setDriverValueInfo(tempAllDriverValues)
      setFinalResults(props.finalResults)
      setRoundValues(props.roundValues)
    } 

    // console.log('ENDdriverValueInfo = ', driverValueInfo)
    // console.log('ENDfinalResults = ', finalResults)
    // console.log('ENDroundValues = ', roundValues)

  })

  const showDriverValues = () => {

    //console.log('showDriverValues = ', driverValueInfo)

    if (driverValueInfo.length > 0) {
      return (
        <div className='driver-object-container'>
          {
            driverValueInfo.map((driverResults, index) =>{
             
              return (
                
                <DriverValuesListItem 
                  key={index}
                  driverResults={driverResults}
                />

              )
            })
          }
        </div>
      )
    }
  }

  return (
    <div className='driver-values-container'>
      <h2>{`Driver Value`}</h2>
      {showDriverValues()}
    </div>
    )
}

export default DriverValues;