import { useState, useEffect } from 'react';
import '../App.css';
//import './DriverValues.css'
import './TrackList.css'
//import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

const TrackList = (props) => {
  // const [finalResults, setFinalResults] = useState([]);
  // const [roundValues, setRoundValues] = useState([]);
  // const [driverValueInfo, setDriverValueInfo] = useState([]);
  const [trackList, setTrackList] = useState([])
  const [roundResults, setRoundResults] = useState([]);

  useEffect(() =>{
    //console.log('roundResults = ', roundResults)
    
    if (trackList.length < 1) {
      setTrackList(props.trackList)
      setRoundResults(props.rounds)
    }

  })

  const driverName = (position, roundNumber) => {

    // console.log('position = ', position)
    // console.log('roundNumber = ', roundNumber)

    if (roundResults.length > roundNumber) {
      const name =  roundResults[roundNumber].race[position].name

      //console.log('name = ', name)

      return name
    } else {

      return '-'
    }
    
  }

  const fastestLap = (roundNumber) => {

    //console.log('roundNumber = ', roundNumber)

    if (roundResults.length > roundNumber) {

      var fastestDriverIs = roundResults[roundNumber].race[0]

      var driversThatRaced = roundResults[roundNumber].race.length - 1

      for (let i = 0; i < driversThatRaced; i++) {

          const nextDriver = roundResults[roundNumber].race[i+1]


          if (nextDriver.fastestLap != 0 && fastestDriverIs.fastestLap > nextDriver.fastestLap) {
            
            fastestDriverIs = nextDriver
          }  
      }

      const minutes = Math.floor(fastestDriverIs.fastestLap / 60000);
      const seconds = ((fastestDriverIs.fastestLap - minutes * 60000)/1000).toFixed(3);

      return `${fastestDriverIs.name} - ${minutes}:${seconds}`

    } else {

      return '-'
    }
  } 


  if (trackList.length > 0) {
    return (
      <div className='track-list-container'>
        <h2>{`Track List`}</h2>
        <div className='track-list-objects-container'>
        {
          trackList.map((circuit, index) =>{
            return (
              <div key={index} className='track-list-object'>
                <div className='track-list-row'>
                  <h3>{`Round ${index+1} - ${circuit}`}</h3>
                </div>

                <div className='track-list-row-with-columns'>
                  <div className='track-list-row-columns'>
                    {`P1`}
                  </div>
                  <div className='track-list-row-columns'>
                    {`P2`}
                  </div>
                  <div className='track-list-row-columns'>
                    {`P3`}
                  </div>
                </div>

                <div className='track-list-row-with-columns'>
                  <div className='track-list-row-columns'>
                    {`${driverName(0, index)}`}
                  </div>
                  <div className='track-list-row-columns'>
                    {`${driverName(1, index)}`}
                  </div>
                  <div className='track-list-row-columns'>
                    {`${driverName(2, index)}`}
                  </div>
                </div>

                <div className='track-list-row'>
                  <div className='driver-value-object-info-container'>
                    <h3>Fastest Lap</h3>
                  </div>
                </div>

                <div className='track-list-row'>
                  <div>
                    {fastestLap(index)}
                  </div>
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

export default TrackList;