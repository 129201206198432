export const driverTeams = (driver) => {
  switch (driver) {
    case 'VZDe09':
      return 'Mercedes-AMG Petronas'
    case 'out_li3r':
      return 'Williams'
    case 'king_lebowski':
      return 'Red Bull'
    case 'spector9600':
      return 'Reb Bull'
    case 'xLIAM_Ux':
      return 'Mercedes-AMG Petronas'
    case 'Liskybusiness':
      return 'Alfa Romeo'
    case 'Golden_Bullet_03':
      return 'Alpine'
    case 'shakz1309':
      return 'Alpha Tauri'
    case 'andrethorn':
      return'Alpha Tauri'
    case 'antssib':
      return'Ferrari'
    case 'account986':
      return 'McLaren'
    case 'BunkyB':
      return 'Aston Martin'
    case 'mrseagull1982':
      return 'Alpha Tauri'
    case 'mReliga':
      return 'Ferrari'
    case 'guuunnnaaa':
      return 'Alfa Romeo'
    case 'KiiNGJP':
      return 'Alpine'
    case 'IRaZoR_JS':
      return 'Aston Martin'
    case 'VinnieDuL':
      return 'Williams'
    case 'TREV1204':
      return 'Ferrari'
    case 'its_jamiebaby':
      return 'Alfa Romeo'
    case 'Ruwy29':
      return 'Aston Martin'
    case 'Skitzy1980':
      return 'McLaren'
    case 'redmannnnns':
      return 'Williams'
    case 'Robbie Shankster':
      return 'Williams'
    case 'Phillam87':
      return 'Haas'
    case 'KUMO':
      return 'Haas'
    default:
    return '-'
  }
}
