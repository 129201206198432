import { useState, useEffect } from 'react';
import '../../App.css';
import '../DriverValues.css'
import { firebaseDB } from '../../firebase'
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

const DriverValuesListItem = (props) => {
  const [showList, setShowList] = useState(false);

  const showEachRoundResults = () => {

    const {valueFromEachRound} = props.driverResults

    return (
      <div>
        <div className='driver-round-value-list-row '>
          <div className='driver-round-value-list-column list-column-left-align list-heading'>
            Rnd
          </div>
          <div className='driver-round-value-list-column list-column-center-align list-heading'>
            Pos
          </div>
          <div className='driver-round-value-list-column list-column-right-align list-heading'>
            Value
          </div>
        </div>

        {
          valueFromEachRound.map((roundresults, index) => {

            const { incrementalValue, racePos } = roundresults
            const roundNumber = index + 1

            var showRacePos = racePos
            if (racePos == 0) {
              showRacePos = '-'
            }

            const formattedNumber = incrementalValue.toLocaleString("en-US");

            var roundValue = `$${formattedNumber}`

            if (incrementalValue < 0) {
              const convertedValue = incrementalValue * -1

              const negativeFormattedNumber = convertedValue.toLocaleString("en-US");

              roundValue = `-$${negativeFormattedNumber}`
            }

            return (
              <div key={index} className='driver-round-value-list-row'>
                <div className='driver-round-value-list-column list-column-left-align '>
                  {`${roundNumber}`}
                </div>
                <div className='driver-round-value-list-column list-column-center-align'>
                  {`${showRacePos}`}
                </div>
                <div className='driver-round-value-list-column list-column-right-align '>
                  {`${roundValue}`}
                </div>
              </div>
             )
          })
        }
      </div>
    )
  }

  const showPreviousYearValue = (pos, value) => {
    const driverValue = (value / 1000000).toFixed(1)
    return (
      <p>2022: P{pos} ${driverValue}</p>
    )
  }

  const showDriverValue = (alias, previousYearsValue, newValue) => {
    
    const driverValue = (newValue / 1000000).toFixed(1)
    const valueDirection = newValue - previousYearsValue

    return(
      <div className='driver-object-value-container'>
        {valueDirection > 0 ? 
          <AiFillCaretUp style={{ color: 'green'}}/>
          :
          <AiFillCaretDown style={{ color: 'red'}}/>
        }
        <h3>${driverValue}M</h3>
      </div> 
    )
  }

  const showDriveObject = () => {
    const { alias,pos, newValue, previousYearsValue, valueFromEachRound } = props.driverResults

    const name = props.driverResults.name

    return (
      <div className='driver-value-object'>

      <div className='driver-value-row'>
        <div className='driver-value-object-info-container'>
          <h3>{alias}</h3>
          <p>{name}</p>        
          {showPreviousYearValue(pos, previousYearsValue)}
        </div>

        {showDriverValue(alias, previousYearsValue, newValue)} 
      </div>

      <div className='driver-round-values-container'>
        <div 
          className='driver-list-drop-down-button'
          onClick={() => setShowList(!showList)}
        >
          {showList ? 
            <AiFillCaretUp style={{ color: 'white'}}/>
            :
            <AiFillCaretDown style={{ color: 'white'}}/>
          }
        </div>
        
        {showList ? 
          showEachRoundResults()
          :
          <div></div>
        }
        
      </div>
    </div>
    )
  }

 
  return (
      <div>
        {showDriveObject()}
      </div>
    )
}

export default DriverValuesListItem;