import firebase from "firebase/app";
// // import "firebase/auth";
// // import "firebase/firestore";

// // import 'firebase/storage';
// // import 'firebase/functions';
// import "firebase/analytics";
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import "firebase/database";

//const { REACT_APP_FIREBASE, REACT_APP_FIREBASE_APP_ID, REACT_APP_FIREBASE_MEASUREMENT_ID } = process.env;
// Initialize Firebase
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7gwzXtpOJUIm3mAaMxrxW6XKBKKbfHk0",
  authDomain: "drivetospin.firebaseapp.com",
  databaseURL: "https://drivetospin-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "drivetospin",
  storageBucket: "drivetospin.appspot.com",
  messagingSenderId: "660184044850",
  appId: "1:660184044850:web:9986ac79019dad6164332b",
  measurementId: "G-S8P6ELRDJY"
};

//firebase.initializeApp(firebaseConfig);

//firebase.initializeApp(config);
export const firebaseApp = firebase.initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebaseApp);
export const firebaseDB = firebaseApp.database();
// export const storage = firebase.storage();
// export var firebaseFunctions = firebase.functions();
// export var firestore = firebase.firestore()
//export const analytics = firebase.analytics();
//export const provider = new firebase.auth.FacebookAuthProvider();
//export const firebaseStorage = firebase.storage();
//export var firebaseFunctions = firebase.functions();
